import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleH1, ArticleHeader, ArticleSection } from "../../styledComponents/article"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Icon from "../../components/icon"


const UpgradeWebsite = ({ data, location, createCookie }) => {
  return (
    <Layout location={location}>
      <Seo
        title="The Internet Consultancy Group"
        description="Is your website running on old technology? The technology used in websites and applications has moved so far in such a short timeframe, we Front-End technologies write the script of success for almost any software infrastructure."
        image=""
        url="/services/upgrade-your-website-technology"
      />
      <HeroTextImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
        <StaticImage
          loading="eager"
          alt=""
          backgroundcolor="#002F4A"
          layout="fullWidth"
          src={
            "../../images/website-technology.png"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1} fontColor="var(--white)">
            Is your website running on old technology? Looking for an update?
          </HeroHeader>
          <HeroDescription>Talk to us about your website, we can take a look under the bonnet and let you know how we can help your website.</HeroDescription>
        </HeroText>
      </HeroTextImageWrapper>
      <Article>
        <ArticleHeader>
          <ArticleH1>Intro to Building Websites with Gatsby and WordPress (Fast and Static)</ArticleH1>
        </ArticleHeader>
        <ArticleSection>
          <p>Curious about how dynamic websites, such as those built on WordPress, operate? It all starts when a visitor enters your URL into their browser or clicks a link to access your site. The browser sends a request to your web server, which retrieves the necessary data via database queries and generates an HTML file to display in the visitor's browser.</p>

          <p>Unlike dynamic sites, static sites store responses as flat files on the server, delivering them instantly to visitors. Although static site generators have been in use for some time, they've recently gained popularity</p>

          <h1>What's Gatsby?</h1>
          <p>Have you heard of Gatsby? Typically, WordPress creates dynamic websites requiring a PHP, MySQL and Apache or Nginx stack on the server to operate. However, you can convert WordPress into a static version by generating a list of HTML pages for all site content.</p>

          <p>This headless or serverless WordPress version is only converted once, enabling the same page to be served to visitors repeatedly. But how do you change your WordPress site to a static version? Enter Gatsby.</p>

          <p>GatsbyJS is a static site generator powered by GraphQL and built with ReactJS. It enables the creation of feature-rich, captivating websites and apps by fetching data from various sources such as existing sites, API calls and flat files through GraphQL. Gatsby builds the static site based on your configuration settings.</p>

          <p>Although Gatsby is only a year old, it has garnered significant attention and adoption across various settings. For example, the home page of Airbnb’s Data Science and Engineering Blog runs on Gatsby, while the actual blog posts are hosted on Medium.</p>
          <StaticImage
            alt=""
            loading="eager"
            backgroundcolor="#002F4A"
            layout="fullWidth"
            src={
              "../../images/wordpress-gatsby.png"
            }
            formats={["auto", "webp", "avif"]}
          />

          <h2>Why use Gatsby? What are the benefits of choosing Gatsby?</h2>

          <ul className="process-ticks single-column">
            <li><Icon name="circle-tick" /><p><strong>Speed:</strong> The primary advantage of using Gatsby to build a static site is speed. Webmasters have been working to optimize load times since Google announced its use of site speed in web search ranking. Slow load times can also negatively impact page views and conversions, with one-second delays potentially resulting in a 7% reduction in conversions.</p></li>
            <li><Icon name="circle-tick" /><p><strong>Security:</strong> Static sites provide added security, as they consist of a collection of static files being served, leaving little room for hacking attempts. If the static files are lost, they can easily be regenerated.</p></li>
            <li><Icon name="circle-tick" /><p><strong>Server Costs:</strong> Hosting a dynamic site requires server compatibility with your technology stack. With a static site, however, you can host it on almost any server, lowering the cost of hosting.</p></li>
          </ul>

          <h2>A new image format for the Web</h2>
          <StaticImage
            loading="eager"
            alt=""
            backgroundcolor="#002F4A"
            layout="fullWidth"
            src={
              "../../images/image-formats.png"
            }
            formats={["auto", "webp", "avif"]}
          />
          <p>WebP is an advanced image format designed for web images that offers high-quality lossless and lossy compression. By using WebP, web developers and webmasters can create smaller, more detailed images that enhance web performance.</p>

          <p>Compared to PNGs, WebP lossless images are 26% smaller in size. For lossy images, WebP images are 25-34% smaller than equivalent JPEG images with similar SSIM quality index.</p>

          <p>WebP lossless format also supports transparency (alpha channel) at just 22% additional bytes, making it a cost-effective option. For lossy RGB compression, WebP also supports transparency, usually delivering file sizes that are 3 times smaller than PNG.</p>

          <p>In addition, WebP supports lossy, lossless and transparency in animated images, which can help reduce file size compared to GIF and APNG.</p>

        </ArticleSection>
      </Article>
    </Layout>
  )
}

export default UpgradeWebsite
